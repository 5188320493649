////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// SELECT 2 /////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$(".modal").on("shown.bs.modal", function () {
    let $modal = $(this);
    let $selects = $modal.find(".select2-hidden-accessible");

    $selects.each(function () {
        let $select = $(this);
        let currentOptions = getSelect2Options($select);

        if ($select.data("select2")) {
            $select.select2("destroy");
        }

        $select.select2(
            $.extend({}, currentOptions, {
                dropdownParent: $modal.find(".modal-content"),
            })
        );
    });
});

$(function () {
    window.initSelect2();
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Dynamic Collections //////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$("body").data("globalCollectionIndex", 0);
const initCollectionWrapper = function () {
    $(".collection-wrapper:not(.init)")
        .addClass("init")
        .each(function () {
            const $body = $("body");
            const current = $body.data("globalCollectionIndex");
            $(this).data("collectionIndex", current);
            $body.data("globalCollectionIndex", current + 1);
        });

    // Indexing
    $(".collection-wrapper .index:not(.init)")
        .addClass("init")
        .each(function () {
            const collectionIndex = $(this)
                .closest(".collection-wrapper")
                .data("collectionIndex");

            $(this).data("collectionIndex", collectionIndex);
        });
    $(".collection-wrapper").each(function () {
        const collectionIndex = $(this).data("collectionIndex");
        let index = 0;
        $(this)
            .find(".index")
            .each(function () {
                if ($(this).data("collectionIndex") !== collectionIndex) {
                    return;
                }

                $(this).text(index + 1);
                index++;
            });
    });

    const collectionItemsContainer = $(
        ".sortable-collection .collection-items"
    );

    if (collectionItemsContainer.length > 0) {
        collectionItemsContainer.sortable({
            handle: ".drag-handle",
            stop: function () {
                const collectionItems = collectionItemsContainer.find("tr");
                if (collectionItems.length > 0) {
                    collectionItems.each(function (index) {
                        const sortierungField =
                            $(this).find(".sortierungsfeld");
                        if (sortierungField.length > 0) {
                            sortierungField.val(index);
                        }
                    });
                }
            },
        });
    }

    registerToggleFields($('#eventSource input[type="checkbox"]'));
    if (typeof rangeInit === "function") {
        rangeInit();
    }
    if (typeof checkAndToggle === "function") {
        checkAndToggle();
    }

    if (typeof updateAddButton === 'function') {
        const collectionWrapper = $('.collection-wrapper');
        updateAddButton(collectionWrapper);
    }
    if (typeof updateRemoveButtons === 'function') {
        const collectionWrapper = $('.collection-wrapper');
        updateRemoveButtons(collectionWrapper);
    }

    if (window.MapboxSearchBox) {
        initAutofill();
    }
};
$(initCollectionWrapper);

$(function () {
    // Add Button
    $(document).on("click", ".collection-wrapper .add-button", function (e) {
        e.preventDefault();
        const collectionWrapper = $(this).closest(".collection-wrapper");
        const collectionItemTarget = collectionWrapper
            .find(".collection-items")
            .first();
        const prototype = collectionWrapper.data("prototype");
        let index = collectionWrapper.data("index");

        collectionItemTarget.append(prototype.replace(/__name__/g, index));
        collectionWrapper.data("index", ++index);
        initCollectionWrapper();
        const collectionItems = $(".collection-items tr");
        if (collectionItems.length > 0) {
            const lastIndex = index;
            const lastRow = collectionItems.eq(lastIndex);
            let sortierungField = lastRow.find(".sortierungsfeld");

            if (sortierungField.length > 0) {
                sortierungField.val(lastIndex);
            }
        }
        window.initSelect2();
    });

    // Remove Button
    $("body").on("click", ".collection-item .remove-button", function (e) {
        const collectionItem = $(this).closest(".collection-item");
        e.preventDefault();
        collectionItem.fadeOut(function () {
            $(this).remove();
            initCollectionWrapper();
        });

        const firstParent = collectionItem.parent();
        const secondParent = firstParent.parent();

        if (
            firstParent.hasClass("card-body") &&
            secondParent.hasClass("card")
        ) {
            secondParent.fadeOut(function () {
                $(this).remove();
                initCollectionWrapper();
            });
        }
    });
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Show / Hide Elements in Forms ////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$(function () {
    const toggleCheckboxHideItems = function (instant = false) {
        let items = $(this).data("showOnCheckItems");
        for (const itemId of items.selectors) {
            let $target = $(itemId);
            if ($(this).is(":checked")) {
                let old = $target.data("oldHtml");
                $target.html(old).fadeIn();
                initCollectionWrapper();

                if (window.MapboxSearchBox) {
                    initAutofill();
                }
            } else {
                $target.data("oldHtml", $target.html());
                if (instant) {
                    $target.hide().html("");
                } else {
                    $target.fadeOut(function () {
                        $target.html("");
                    });
                }
            }
        }
    };

    const dataShowOnCheckItems = $("[data-show-on-check-items]");
    dataShowOnCheckItems.each(function () {
        const fn = toggleCheckboxHideItems.bind(this);
        fn(true);
    });
    dataShowOnCheckItems.on("change", toggleCheckboxHideItems);

    function toggleRadio(loop) {
        let items = $(this).data("showOnRadioItems");
        let showValue = $(this).data("showValue");
        let multipleItems = $(this).data("multipleItems");
        if (multipleItems) {
            let checkboxID = $(this).attr("id");
            let inputID = checkboxID.replace("Bekannt", "");
            if ($(this).parent().find("input:checked").val() === showValue) {
                $("#" + inputID)
                    .parent()
                    .fadeIn();
            } else {
                $("#" + inputID)
                    .parent()
                    .hide();
            }
        } else {
            for (const itemId of items.selectors) {
                if ($(this).find("input:checked").val() === showValue) {
                    if (loop) {
                    } else {
                        $(itemId).parent().fadeIn();
                    }
                } else {
                    $(itemId).parent().hide();
                }
            }
        }
    }

    function toggleAllRadio() {
        const dataShowOnRadioItems = $("[data-show-on-radio-items]");
        dataShowOnRadioItems.each(function () {
            toggleRadio.call(this, true);
        });

        dataShowOnRadioItems.on("change", function () {
            toggleRadio.call(this, false);
        });
    }

    toggleAllRadio();

    const hideOnEmptySelect = function () {
        let items = $(this).data("showOnSelectOther");
        let idx = 0;
        for (const itemId of items.selectors) {
            let $target = $(itemId);
            if ($(this).val() === "") {
                let old = $target.data("oldHtml" + idx);
                $target.html(old).fadeIn();
            } else {
                $target.data("oldHtml" + idx, $target.html());
                $target.fadeOut(function () {
                    $target.html("");
                });
            }
        }
    };
    const dataShowOnSelectOther = $("[data-show-on-select-other]");
    dataShowOnSelectOther.each(hideOnEmptySelect);
    dataShowOnSelectOther.on("change", hideOnEmptySelect);

    // EntityOrCreateTypeDropdown
    $(".EntityOrCreateTypeDropdown").on("change", function (e) {
        let $parent = $(this).closest("fieldset");
        let $target = $parent.find("fieldset").first();
        if (e.target.value === "") {
            let old = $(this).data("oldHtml");
            $parent.append(old);
            old.fadeIn();
        } else {
            $(this).data("oldHtml", $target);
            $target.fadeOut(function () {
                $target.remove();
            });
        }
    });
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Notifications ////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
$(function () {
    $.ajax({
        url: "/api/notifications.jsonld",
        dataType: "json",
        success: function (response) {
            let $notificationDropdown = $(
                "#page-header-notifications-dropdown-wrapper"
            );
            let $notificationTarget = $(
                $notificationDropdown.find(".notification-items-target")
            );
            let $template = $notificationTarget
                .find(".notification-item")
                .first();

            let count = response["hydra:totalItems"];

            if (count === 0) {
                let $item = $template.clone();
                $item
                    .find("[data-key='icon']")
                    .attr("class", "fas fa-check-circle");
                $item.find("[data-key='title']").text("Alles erledigt!");
                $item
                    .find("[data-key='body']")
                    .text("Aktuell gibt es keine Benachrichtigungen!");
                $item
                    .find("[data-key='iconwrapper']")
                    .removeClass("bg-primary")
                    .addClass("bg-success");
                $notificationTarget.html($item);
                return;
            }

            if (count > 0) {
                let $counter = $notificationDropdown.find(".counter");
                $counter.html(count).removeClass("d-none");
                // $notificationDropdown.find(".bellicon").addClass("fa-shake");
            }

            $notificationTarget.html("");
            for (let notification of response["hydra:member"]) {
                let $item = $template.clone();
                $item
                    .find("[data-key='icon']")
                    .attr("class", notification.icon);
                $item.find("[data-key='title']").text(notification.title);
                $item.find("[data-key='body']").text(notification.body);
                $item
                    .find("[data-key='iconwrapper']")
                    .removeClass("bg-primary")
                    .addClass("bg-" + notification.color);
                $item.attr("href", notification.url);
                $notificationTarget.append($item);
            }
        },
    });
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// AJAX Content /////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initAjaxContent = function () {
    $(".ajax-content:not(.init)")
        .each(function () {
            const reload = () => {
                const $target = $(this).data("childTarget")
                    ? $(this).find($(this).data("childTarget"))
                    : $(this);

                $.ajax({
                    url: $(this).data("url"),
                    dataType: "json",
                    success: (data) => {
                        if (data["content"] && data["content"].trim() !== "") {
                            $target.html(data["content"]);
                            $(this).removeClass("d-none");
                        } else {
                            $(this).addClass("d-none");
                        }
                    },
                    error: () => {
                        const $html = $(
                            '<div class="alert alert-danger hover" ><b><i class="far fa-exclamation-circle" aria-hidden="true"></i> Info</b><br>Der Inhalt konnte nicht geladen werden<br />Klicken Sie um es erneut zu versuchen</div>'
                        );
                        $html.on("click", function () {
                            $target.html(
                                '<i class="fa fa-spin fa-spinner"></i> Versuche erneut zu laden...'
                            );
                            reload();
                        });
                        $target.html($html);
                    },
                    complete: function () {
                        $(this).trigger("ajaxcontent-reloaded");
                    },
                });
            };

            reload();
            $(this).on("ajaxcontent-reload", reload);
        })
        .addClass("init");
};
$(initAjaxContent);
$(window).ajaxComplete(initAjaxContent);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Termin/Vorschlag Nodal ///////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Utility function to parse German-formatted date strings
function parseGermanDate(dateString) {
    const parts = dateString.split(" ");
    const dateParts = parts[0].split(".");
    const timeParts = parts.length > 1 ? parts[1].split(":") : ["0", "0", "0"];
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // JavaScript's months are 0-indexed
    const year = parseInt(dateParts[2], 10);
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = timeParts.length > 2 ? parseInt(timeParts[2], 10) : 0;
    return new Date(year, month, day, hours, minutes, seconds);
}

export const checkDate = function (
    deadline,
    inputSelector,
    remarkSelector,
    labelSelector,
    warningText
) {
    $(inputSelector).on("hide.td", function () {
        const input = $(this);
        const selectedDate = parseGermanDate(input.val());
        const deadlineDate = parseGermanDate(deadline);
        const remarkField = $(remarkSelector);
        const remarkLabel = $(labelSelector);
        const heute = new Date();
        heute.setHours(0, 0, 0, 0);

        if (selectedDate > deadlineDate) {
            Swal.fire({
                title: "Achtung!",
                text: warningText,
                icon: "warning",
                confirmButtonText: "OK",
                allowOutsideClick: false,
            });

            remarkField.attr("required", "required");

            if (!remarkLabel.html().includes("*")) {
                remarkLabel.html("* " + remarkLabel.html());
            }
        } else {
            remarkField.removeAttr("required");
            remarkLabel.html(remarkLabel.html().replace("* ", ""));

            if (selectedDate < heute) {
                Swal.fire({
                    title: "Ungültiges Datum!",
                    text: "Termine in der Vergangenheit sind nicht zulässig. Bitte wählen Sie ein Datum, welches in der Zukunft liegt.",
                    icon: "error",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                });

                input.val("");
            }
        }
    });
};

window.checkDate = checkDate;

export const checkGueltigkeitsenddatum = async function (
    datumInputId,
    gueltigkeitsenddatumTimeInputId
) {
    const datumInput = $(datumInputId);
    const gueltigkeitsenddatumTimeInput = $(gueltigkeitsenddatumTimeInputId);

    if (gueltigkeitsenddatumTimeInput.val() === "") {
        setDefaultData();
    }

    datumInput.on("hide.td", function () {
        const terminvorschlagsDatum = parseGermanDate($(this).val());

        const gueltigkeitsDatum = new Date(terminvorschlagsDatum.getTime());
        gueltigkeitsDatum.setDate(gueltigkeitsDatum.getDate() - 2);

        const now = new Date();

        if (gueltigkeitsDatum < now) {

            gueltigkeitsDatum.setDate(gueltigkeitsDatum.getDate() + 1);
        }

        window.setDatepickerDate(
            gueltigkeitsenddatumTimeInput,
            gueltigkeitsDatum
        );

        const ausgewaehltesGueltigkeitsenddatum = parseGermanDate(
            $(gueltigkeitsenddatumTimeInput).val()
        );

        validateGueltigkeitsenddatum(
            ausgewaehltesGueltigkeitsenddatum,
            terminvorschlagsDatum,
        );
    });

    $(gueltigkeitsenddatumTimeInput).on("hide.td", function () {
        const ausgewaehltesGueltigkeitsenddatum = parseGermanDate(
            $(this).val()
        );
        const terminvorschlagsDatum = parseGermanDate($(datumInput).val());

        validateGueltigkeitsenddatum(
            ausgewaehltesGueltigkeitsenddatum,
            terminvorschlagsDatum,
        );
    });

    function setDefaultData() {
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 7);

        const defaultGueltigkeitsDatum = new Date(defaultDate.getTime());
        defaultGueltigkeitsDatum.setDate(
            defaultGueltigkeitsDatum.getDate() - 2
        );
        window.setDatepickerDate(
            gueltigkeitsenddatumTimeInput,
            defaultGueltigkeitsDatum
        );
    }

    function validateGueltigkeitsenddatum(
        gueltigkeitsenddatum,
        terminvorschlagsDatum,
    ) {
        const minGueltigkeitsenddatum = new Date();
        minGueltigkeitsenddatum.setDate(minGueltigkeitsenddatum.getDate() + 2);

        const gueltigkeitsfristAussetzen = $('#installation_termin_vorschlag_gueltigkeitsfristAussetzen');
        if (!gueltigkeitsfristAussetzen.prop('checked')) {
            if (gueltigkeitsenddatum > terminvorschlagsDatum) {
                showAlert(
                    "Das Gültigkeitsenddatum darf nicht nach dem vorgeschlagenen Termin liegen."
                );

                window.setDatepickerDate(
                    gueltigkeitsenddatumTimeInput,
                    terminvorschlagsDatum
                );
            } else if (gueltigkeitsenddatum < minGueltigkeitsenddatum) {
                showAlert(
                    "Das Gültigkeitsenddatum muss mindestens 2 Tage in der Zukunft liegen."
                );

                const gueltigkeitsDatum = new Date(
                    minGueltigkeitsenddatum.getTime()
                );
                gueltigkeitsDatum.setDate(gueltigkeitsDatum.getDate() - 2);
                window.setDatepickerDate(
                    gueltigkeitsenddatumTimeInput,
                    gueltigkeitsDatum
                );
            }
        }
    }

    function showAlert(message) {
        Swal.fire({
            title: "Achtung!",
            text: message,
            icon: "error",
            confirmButtonText: "OK",
            allowOutsideClick: false,
        });
    }
};

window.checkGueltigkeitsenddatum = checkGueltigkeitsenddatum;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////// Forms ///////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function toggleFields($element) {
    if ($element.length === 0) {
        return;
    }

    $(".hide").hide();

    let selectedValue;

    let $parentElement = $element.closest("[data-show-on-change]");

    if ($element.is("select")) {
        selectedValue = $("option:selected", $element).text();
    } else if ($element.is(":checkbox")) {
        selectedValue = $element.is(":checked") ? 1 : 0;
    } else if ($element.is(":radio")) {
        selectedValue = $element.filter(":checked").val();
    }

    const dataShowOnChange = $parentElement.attr("data-show-on-change");

    if (!dataShowOnChange) {
        return;
    }
    let showOnChange = JSON.parse(dataShowOnChange);
    const selectorsToShow = showOnChange["selectors"][selectedValue];
    if (selectorsToShow) {
        selectorsToShow.forEach(function (selector) {
            $("#" + selector).fadeIn();
        });
    }
}

window.registerToggleFields = function registerToggleFields($selector) {

    if ($selector.length) {
        toggleFields($selector);
        $selector.change(function () {
            toggleFields($(this));
        });
    }
}

registerToggleFields($("#eventSource select"));
registerToggleFields($("#eventSource-2 select"));
registerToggleFields($('#eventSource input[type="checkbox"]'));
registerToggleFields($('#eventSource input[type="radio"]'));

function toggleKimHinweis() {
    // Use the stored jQuery object instead of selecting again from the DOM
    const isChecked = $checkbox.prop("checked");
    if (isChecked) {
        $("#kim-hinweis").show();
    } else {
        $("#kim-hinweis").hide();
    }
}

// Store the jQuery object in a variable to avoid re-selecting from the DOM
const $checkbox = $('#eventSource-3  input[type="checkbox"]');

// Call the function initially in case the checkbox's state should affect the hint on page load
toggleKimHinweis();

// Attach the change event listener using the stored jQuery object
$checkbox.change(function () {
    toggleKimHinweis();
});

$(".show-loader").click(function () {
    showLoader();
});

function showLoader() {
    Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
            Swal.showLoading();
        },
    });
}

// Anwendung der Funktion auf alle Formulare beim Laden der Seite
document.addEventListener("DOMContentLoaded", function () {
    $(function () {

        let backToTopButton = document.getElementById("btn-back-to-top");

        if (backToTopButton) {
            function checkWindowWidth() {
                if (window.innerWidth < 901) {
                    window.onscroll = function () {
                        scrollFunction();
                    };

                    function scrollFunction() {
                        if (
                            document.body.scrollTop > 20 ||
                            document.documentElement.scrollTop > 20
                        ) {
                            backToTopButton.style.display = "block";
                        } else {
                            backToTopButton.style.display = "none";
                        }
                    }

                    backToTopButton.addEventListener("click", backToTop);

                    function backToTop() {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                    }
                }
            }

            window.addEventListener("load", checkWindowWidth);

            window.addEventListener("resize", checkWindowWidth);
        }
    });
});

import "./global";
