import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

global.mapboxgl = window.mapboxgl = mapboxgl;
global.turf = window.turf = turf;
window.MapboxLanguage = MapboxLanguage;


class MapboxWrapper {
    constructor(initElemId, zoom = 5, center = [10.4478313, 51.1638175]) {
        this.initElemId = initElemId;
        this.zoom = zoom;
        this.center = center;
    }

    init(mapElementName, cen) {
        if (!this.isWebGLAvailable()) {
            this.showErrorOverlay(
                "Bedauerlicherweise ist die Darstellung der Karte nicht möglich. Dies könnte auf eine fehlende Unterstützung von WebGL durch Ihren Browser zurückzuführen sein, darauf dass er veraltet ist oder dass die Hardwarebeschleunigung ausgeschaltet wurde. Aktualisieren Sie bitte Ihren Browser oder schalten Sie die Hardwarebeschleunigung in den Einstellungen ein, um die Karte betrachten zu können."
            );

            return;
        }
        this.map = new mapboxgl.Map({
            container: this.initElemId,
            style: "mapbox://styles/mapbox/navigation-night-v1",
            center: this.center,
            zoom: this.zoom,
        });

        this.map.addControl(
            new MapboxLanguage({
                defaultLanguage: "de",
            })
        );
    }

    addMarker(lng, lat, html, markerColor, options = {}) {
        const marker = new mapboxgl.Marker({ color: markerColor }).setLngLat([lng, lat]).addTo(this.map);

        return marker;
    }

    addMarkerWithPopup(lng, lat, html, markerColor, options = {}) {
        const popup = new mapboxgl.Popup({
            offset: 25,
            ...options,
        });
        popup.setHTML(html);

        const marker = new mapboxgl.Marker({ color: markerColor })
            .setLngLat([lng, lat])
            .setPopup(popup)
            .addTo(this.map);

        return popup;
    }

    getMap() {
        return this.map;
    }

    isWebGLAvailable() {
        try {
            let canvas = document.createElement("canvas");
            return (
                !!window.WebGLRenderingContext &&
                (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
            );
        } catch (e) {
            return false;
        }
    }

    showErrorOverlay(message) {
        const mapContainer = document.getElementById(this.initElemId);
        const overlay = document.createElement("div");
        overlay.style.position = "absolute";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.backgroundColor = "rgba(0,0,0,0.75)";
        overlay.style.color = "white";
        overlay.style.display = "flex";
        overlay.style.justifyContent = "center";
        overlay.style.alignItems = "center";
        overlay.style.textAlign = "center";
        overlay.style.zIndex = "1000";
        overlay.innerHTML = `<p style="max-width: 80%;">${message}</p>`;
        mapContainer.appendChild(overlay);
    }
}

window.MapboxWrapper = MapboxWrapper;
