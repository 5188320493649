const datatableDe = {
    sEmptyTable: "Keine Daten in der Tabelle vorhanden",
    sInfo: "_START_ bis _END_ von _TOTAL_ Einträgen",
    sInfoEmpty: "Keine Daten vorhanden",
    sInfoFiltered: "(gefiltert von _MAX_ Einträgen)",
    sInfoThousands: ".",
    sLengthMenu: "_MENU_ Einträge anzeigen",
    sLoadingRecords: "Wird geladen ..",
    sProcessing: "Bitte warten ..",
    sSearch: "Suchen",
    sZeroRecords: "Keine Einträge vorhanden",
    oPaginate: {
        sFirst: "Erste",
        sPrevious: "Zurück",
        sNext: "Nächste",
        sLast: "Letzte",
    },
    emptyTable: "Keine Daten in der Tabelle vorhanden",
    info: "_START_ bis _END_ von _TOTAL_ Einträgen",
    infoEmpty: "Keine Daten vorhanden",
    infoFiltered: "(gefiltert von _MAX_ Einträgen)",
    infoThousands: ".",
    loadingRecords: "Wird geladen ..",
    processing: "Bitte warten ..",
    paginate: {
        first: "Erste",
        next: "Nächste",
        last: "Letzte",
        previous: "Vorherige",
    },
    oAria: {
        sSortAscending: ": aktivieren, um Spalte aufsteigend zu sortieren",
        sSortDescending: ": aktivieren, um Spalte absteigend zu sortieren",
    },
    aria: {
        sortAscending: ": aktivieren, um Spalte aufsteigend zu sortieren",
        sortDescending: ": aktivieren, um Spalte absteigend zu sortieren",
    },
    select: {
        rows: {
            _: "%d Zeilen ausgewählt",
            1: "1 Zeile ausgewählt",
        },
        cells: {
            1: "1 Zelle ausgewählt",
            _: "%d Zellen ausgewählt",
        },
        columns: {
            1: "1 Spalte ausgewählt",
            _: "%d Spalten ausgewählt",
        },
    },
    buttons: {
        print: "Drucken",
        copy: "Kopieren",
        copyTitle: "In Zwischenablage kopieren",
        copySuccess: {
            _: "%d Zeilen kopiert",
            1: "1 Zeile kopiert",
        },
        collection: 'Aktionen <span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span>',
        colvis: "Spaltensichtbarkeit",
        colvisRestore: "Sichtbarkeit wiederherstellen",
        csv: "CSV",
        excel: "Excel",
        pageLength: {
            "-1": "Alle Zeilen anzeigen",
            1: "Zeige 1 Zeile",
            _: "Zeige %d Zeilen",
        },
        pdf: "PDF",
        createState: "Ansicht erstellen",
        removeAllStates: "Alle Ansichten entfernen",
        removeState: "Entfernen",
        renameState: "Umbenennen",
        savedStates: "Gespeicherte Ansicht",
        stateRestore: "Ansicht %d",
        updateState: "Aktualisieren",
        copyKeys:
            "Taste <i>ctrl</i> oder <i>⌘</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
    },
    autoFill: {
        cancel: "Abbrechen",
        fill: "Alle Zellen mit <i>%d<i> füllen</i></i>",
        fillHorizontal: "Alle horizontalen Zellen füllen",
        fillVertical: "Alle vertikalen Zellen füllen",
        info: "Automatische Vervollständigung",
    },
    decimal: ",",
    search: "Suche:",
    searchBuilder: {
        add: "+",
        button: {
            0: "Such-Baukasten",
            _: "Such-Baukasten (%d)",
        },
        condition: "Bedingung",
        conditions: {
            date: {
                after: "Nach",
                before: "Vor",
                between: "Zwischen",
                empty: "Leer",
                not: "Nicht",
                notBetween: "Nicht zwischen",
                notEmpty: "Nicht leer",
                equals: "Gleich",
            },
            number: {
                between: "Zwischen",
                empty: "Leer",
                equals: "Entspricht",
                gt: "Größer als",
                gte: "Größer als oder gleich",
                lt: "Kleiner als",
                lte: "Kleiner als oder gleich",
                not: "Nicht",
                notBetween: "Nicht zwischen",
                notEmpty: "Nicht leer",
            },
            string: {
                contains: "Beinhaltet",
                empty: "Leer",
                endsWith: "Endet mit",
                equals: "Entspricht",
                not: "Nicht",
                notEmpty: "Nicht leer",
                startsWith: "Startet mit",
                notContains: "enthält nicht",
                notStartsWith: "startet nicht mit",
                notEndsWith: "endet nicht mit",
            },
            array: {
                equals: "ist gleich",
                empty: "ist leer",
                contains: "enthält",
                not: "ist ungleich",
                notEmpty: "ist nicht leer",
                without: "aber nicht",
            },
        },
        data: "Column",
        deleteTitle: "Löschen",
        leftTitle: "Links",
        rightTitle: "Rechts",
        title: {
            0: "Filter",
            _: "Filter (%d)",
            _: "Such-Baukasten (%d)",
        },
        value: "Option",
        clearAll: "Zurücksetzen",
        logicAnd: "Und",
        logicOr: "Oder",
    },
    searchPanes: {
        clearMessage: "Leeren",
        collapse: {
            0: "Suchmasken",
            _: "Suchmasken (%d)",
        },
        countFiltered: "{shown} ({total})",
        emptyPanes: "Keine Suchmasken",
        title: "Aktive Filter: %d",
        showMessage: "zeige Alle",
        collapseMessage: "Alle einklappen",
        count: "{total}",
        loadMessage: "Lade Suchmasken...",
    },
    thousands: ".",
    zeroRecords: "Keine passenden Einträge gefunden",
    lengthMenu: "_MENU_ Zeilen anzeigen",
    datetime: {
        previous: "Vorher",
        next: "Nachher",
        hours: "Stunden",
        minutes: "Minuten",
        seconds: "Sekunden",
        unknown: "Unbekannt",
        weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        months: [
            "Januar",
            "Februar",
            "März",
            "April",
            "Mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember",
        ],
    },
    editor: {
        close: "Schließen",
        create: {
            button: "Neu",
            title: "Neuen Eintrag erstellen",
            submit: "Erstellen",
        },
        remove: {
            confirm: {
                _: "Sollen %d Zeilen gelöscht werden?",
                1: "Soll diese Zeile gelöscht werden?",
            },
            button: "Entfernen",
            title: "Entfernen",
            submit: "Entfernen",
        },
        error: {
            system: "Ein Systemfehler ist aufgetreten",
        },
        multi: {
            title: "Mehrere Werte",
            info: "Die ausgewählten Elemente enthalten mehrere Werte für dieses Feld. Um alle Elemente für dieses Feld zu bearbeiten und auf denselben Wert zu setzen, klicken oder tippen Sie hier, andernfalls behalten diese ihre individuellen Werte bei.",
            restore: "Änderungen zurücksetzen",
            noMulti: "Dieses Feld kann nur einzeln bearbeitet werden, nicht als Teil einer Mengen-Änderung.",
        },
        edit: {
            button: "Bearbeiten",
            title: "Eintrag bearbeiten",
            submit: "Bearbeiten",
        },
    },
    searchPlaceholder: "Suchen...",
    stateRestore: {
        creationModal: {
            button: "Erstellen",
            columns: {
                search: "Spalten Suche",
                visible: "Spalten Sichtbarkeit",
            },
            name: "Name:",
            order: "Sortieren",
            paging: "Seiten",
            scroller: "Scroll Position",
            search: "Suche",
            searchBuilder: "Such-Baukasten",
            select: "Auswahl",
            title: "Neue Ansicht erstellen",
            toggleLabel: "Inkludiert:",
        },
        duplicateError: "Eine Ansicht mit diesem Namen existiert bereits.",
        emptyError: "Name darf nicht leer sein.",
        emptyStates: "Keine gespeicherten Ansichten",
        removeConfirm: "Bist du dir sicher, dass du %s entfernen möchtest?",
        removeError: "Entfernen der Ansicht fehlgeschlagen.",
        removeJoiner: " und ",
        removeSubmit: "Entfernen",
        removeTitle: "Ansicht entfernen",
        renameButton: "Umbenennen",
        renameLabel: "Neuer Name für %s:",
        renameTitle: "Ansicht umbenennen",
    },
};

$(function () {
    $(document).trigger("defaultDataTableOptionsDefined");
    const datatable = $(".datatable");
    if (datatable.find("tbody tr").length > 0) {
        const table = datatable.DataTable(window.defaultDataTableOptions);

        const $table = $(this);
        $(window).resize(function () {
            window.refresh_size = setTimeout(function () {
                $table.css("width", "100%");
                table.columns.adjust().responsive.recalc().responsive.rebuild().draw(false);
            }, 250);
        });

    }
});

const options = {
    fixedHeader: true,
    responsive: true,
    searchBuilder: true,
    columnDefs: [
        {
            searchPanes: {
                show: false,
            },
            targets: ["_all"],
        },
        {
            orderable: true,
            className: "select-checkbox",
        },
    ],
    layout: {
        top1: "searchPanes",
    },
    order: [1],
    lengthMenu: [
        [10, 25, 50, 100],
        ["10 Zeilen", "25 Zeilen", "50 Zeilen", "100 Zeilen"],
    ],
    colReorder: true,
    language: datatableDe,
};
window.defaultDataTableOptions = options;

export default options;
