import "select2";
import "select2/dist/js/i18n/de.js";
window.initSelect2 = function () {
    $("select:not(.plain)").select2({
        language: "de",
        width: "100%",
        placeholder: $(this).data("placeholder") ?? "Bitte wählen",
        minimumResultsForSearch: 10,
        tags: $(this).data("tags") ?? false,
        allowClear: $(this).data("allowClear") ?? false,
    });
};

window.getSelect2Options = function ($element) {
    return $element.data("select2") ? $element.data("select2").options.options : {};
};

$(window).on("init.dt", function (event, p2) {
    console.log();
    $(event.dt.table().container()).find("thead select").select2();
});
