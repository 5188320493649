/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import "./js/_jquery.js";
import "./js/_mapbox-search";
import "./js/_datatables.js";
import "./js/_moment.js";
import "./js/_bootstrap.js";
import "./js/_select2.js";
import "./js/_datepicker.js";

import Swal from "sweetalert2";
import "metismenu/dist/metisMenu.min.js";
import "simplebar/dist/simplebar.min.js";
import "node-waves/dist/waves.min.js";

global.Swal = Swal;
import "./custom-app.js";
import "./theme-app.js";
