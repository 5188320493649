import Inputmask from "inputmask";
import { TempusDominus } from "@eonasdan/tempus-dominus";
import dayjs from "dayjs";
import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.css";
import "dayjs/locale/de";

dayjs.locale("de");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

document.addEventListener("DOMContentLoaded", function() {
    $(function() {
        const datepickerElements = document.querySelectorAll(
            ".js-datepicker-quarter-minutes"
        );

        datepickerElements.forEach(function(el) {
            let currentValue = $(el).val();
            let componentDate = true;
            let componentHours = true;
            let componentMinutes = true;
            let sideBySide = true;
            let dynamicHolidays = [];

            const inputTime1 = " HH:mm";
            const inputTime2 = " HH:MM";
            let useTime = true;

            if ($(el).data("componentdate") !== undefined) {
                componentDate = $(el).data("componentdate");
            }
            if ($(el).data("componenthours") !== undefined) {
                componentHours = $(el).data("componenthours");
            }
            if ($(el).data("componentminutes") !== undefined) {
                componentMinutes = $(el).data("componentminutes");
            }
            if (componentHours === false && componentMinutes === false) {
                sideBySide = false;
                useTime = false;
            }

            if ($(el).data("dynamicholidays") !== undefined) {
                dynamicHolidays = $(el).data("dynamicholidays");
                dynamicHolidays = dynamicHolidays.map(function(item) {
                    let itemsplit = item.split("-");
                    if (itemsplit.length === 3) {
                        return getNextHolidayDate(itemsplit[2], itemsplit[1], itemsplit[0]);
                    }
                    return item;
                });

            }


            // Check if currentValue is defined and not empty
            if (currentValue) {
                // Attempt to parse the currentValue to a Date object
                let parsedDate = new Date(currentValue);

                // Check if the parsed date is valid
                if (!isNaN(parsedDate.getTime())) {
                    // Use dayjs to format the valid date into German date format
                    let formattedDate =
                        dayjs(parsedDate).format(`DD.MM.YYYY${useTime ? inputTime1 : ""}`);

                    // Set the formatted German date as the new value of the input field
                    $(el).val(formattedDate);
                }
            }

            function getNextHolidayDate(day, month, currentYear) {
                const today = new Date();
                if (currentYear === undefined) {
                    currentYear = today.getFullYear();
                }
                let holidayDate = new Date(currentYear, month - 1, day);

                // Wenn das Datum in der Vergangenheit liegt, nutze das nächste Jahr
                if (holidayDate < today) {
                    holidayDate.setFullYear(currentYear + 1);
                }

                return holidayDate;
            }

            const germanHolidays = [
                getNextHolidayDate(1, 1), // Neujahrstag - 1. Januar
                // Karfreitag - das Datum variiert, hier muss eine Berechnungsfunktion für das Osterdatum verwendet werden
                // Ostermontag - das Datum variiert ebenfalls, basierend auf dem Osterdatum
                getNextHolidayDate(1, 5), // Tag der Arbeit - 1. Mai
                // Christi Himmelfahrt - 39 Tage nach Ostersonntag, ebenfalls variierend
                // Pfingstmontag - 50 Tage nach Ostersonntag, ebenfalls variierend
                getNextHolidayDate(25, 12), // 1. Weihnachtstag - 25. Dezember
                getNextHolidayDate(26, 12), // 2. Weihnachtstag - 26. Dezember
            ];

            const easterSunday = calculateEaster(new Date().getFullYear());
            let goodFriday = new Date(
                easterSunday.getTime() - 2 * 24 * 60 * 60 * 1000
            ); // Karfreitag, 2 Tage vor Ostersonntag
            let easterMonday = new Date(
                easterSunday.getTime() + 1 * 24 * 60 * 60 * 1000
            ); // Ostermontag, 1 Tag nach Ostersonntag

            if (goodFriday < new Date()) {
                const nextYearEasterSunday = calculateEaster(
                    new Date().getFullYear() + 1
                );
                goodFriday = new Date(
                    nextYearEasterSunday.getTime() - 2 * 24 * 60 * 60 * 1000
                );
            }

            if (easterMonday < new Date()) {
                const nextYearEasterSunday = calculateEaster(
                    new Date().getFullYear() + 1
                );
                easterMonday = new Date(
                    nextYearEasterSunday.getTime() + 1 * 24 * 60 * 60 * 1000
                );
            }

            function calculateEaster(year) {
                const f = Math.floor, // Golden Number - 1
                    G = year % 19,
                    C = f(year / 100), // Related to Epact
                    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30, // Number of days from 21 March to the Paschal full moon
                    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)), // Weekday for the Paschal full moon
                    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7, // Number of days from 21 March to the Sunday on or before the Paschal full moon
                    L = I - J,
                    month = 3 + f((L + 40) / 44),
                    day = L + 28 - 31 * f(month / 4);

                return new Date(year, month - 1, day);
            }

            germanHolidays.push(goodFriday);
            germanHolidays.push(easterMonday);
            const holidays = [...germanHolidays, ...dynamicHolidays];

            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 2);

            const picker = new TempusDominus(el, {
                stepping: 15,
                debug: false,
                allowInputToggle: true,
                localization: {
                    locale: "de",
                    format: `dd.MM.yyyy${useTime ? inputTime1 : ""}`,
                    startOfTheWeek: 1,
                },
                display: {
                    keepOpen: true,
                    sideBySide: sideBySide,
                    theme: "dark",
                    components: {
                        decades: true,
                        year: true,
                        month: true,
                        date: componentDate,
                        hours: componentHours,
                        minutes: componentMinutes,
                        seconds: false
                    }
                },
                restrictions: {
                    minDate: new Date(),
                    disabledDates: holidays,
                    daysOfWeekDisabled: [0, 6],
                    enabledHours: [
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                        23,
                    ],
                },
                defaultDate: currentDate,
            });
            $(el).data("datepicker", picker);

            Inputmask("datetime", {
                inputFormat: `dd.mm.yyyy${useTime ? inputTime2 : ""}`,
                alias: "datetime",
                hourFormat: "24",
            }).mask(el);

            // Event listener to adjust minutes to nearest 15-minute increment upon input change
            el.addEventListener("change", function() {
                const inputValue = this.value;
                const dateTime = dayjs(inputValue, `DD.MM.YYYY${useTime ? inputTime1 : ""}`);

                if (!dateTime.isValid()) return; // Exit if not valid

                const minutes = dateTime.minute();
                const remainder = minutes % 15;
                const adjustedMinutes =
                    remainder < 8
                        ? minutes - remainder
                        : minutes + (15 - remainder);

                const correctedDateTime = dateTime
                    .minute(adjustedMinutes)
                    .second(0);
                this.value = correctedDateTime.format(`DD.MM.YYYY${useTime ? inputTime1 : ""}`);
            });
        });
    });
});

window.setDatepickerDate = (input, date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding leading zero if necessary
    const day = ("0" + date.getDate()).slice(-2); // Adding leading zero if necessary
    const hours = "00";
    const minutes = "00";

    const datepicker = $(input).data("datepicker");
    if (datepicker) {
        datepicker.updateOptions({
            defaultDate: date,
        });
    }
    input.val(`${day}.${month}.${year} ${hours}:${minutes}`);
};
