import * as MapboxSearchBox from "@mapbox/search-js-web";
window.MapboxSearchBox = MapboxSearchBox;

import '../templates/core/mapbox'

window.initAutofill = function () {
    if ($('.mapsearch-autofill').length !== 0) {
        $("mapbox-search-listbox").remove();
        window.MapboxSearchBox.autofill({
            accessToken: window.mapBoxToken,
            options: {
                country: "de",
                language: "de",
            },
            browserAutofillEnabled: true,
            popoverOptions: {
                placement: "bottom-start",
                flip: true,
                offset: 5,
            },
        });
    }
};
